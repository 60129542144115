:root {
  --primary-color: #007bff;
  --heading-display: rgb(216, 212, 207);
  --heading-color: rgb(179, 208, 199);
  /*
  --accent-color: rgb(54, 248, 184);
  */
  --accent-color: #8d86c9;
  --text-color: rgb(177, 170, 160);
  --card-background: #242038;
  /*
  --background: rgb(2, 24, 24);
  */
  /*
  --background: #152614;
  */
  --background: #131515;
  --secondary-text: rgb(176, 169, 159);
  --near-white-text: rgb(216, 212, 207);
  --header-bg: rgba(128, 128, 128, 0.148);
  --footer-bg: rgba(128, 128, 128, 0.148);
}

body {
  margin: 0; /* Remove default body margin */
}

.app {
  font-family: "Inter", sans-serif;
  font-family: "Roboto", sans-serif;
  color: var(--secondary-text);
  background-color: var(--background);
}

.fade-in-section {
  opacity: 0;
  transition: opacity 1s ease;
}

.fade-in-section.is-visible {
  opacity: 1;
}

/* Extra small devices (phones) */
@media screen and (max-width: 575.98px) {
  /* Your styles for extra small screens go here */
}

/* Small devices (tablets) */
@media screen and (min-width: 576px) and (max-width: 767.98px) {
  /* Your styles for small screens go here */
}

/* Medium devices (desktops) */
@media screen and (min-width: 768px) and (max-width: 991.98px) {
}

/* Large devices (desktops) */
@media screen and (min-width: 992px) and (max-width: 1199.98px) {
  /* Your styles for large screens go here */
}

/* Extra large devices (large desktops) */
@media screen and (min-width: 1200px) {
  /* Your styles for extra large screens go here */
  .app-body {
    padding-left: 10%;
    padding-right: 10%;
  }
}
