.intro {
  max-width: 600;
  margin: 0 auto;
}
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
}

.display-text {
  font-family: "Red Hat Display", sans-serif;
}

.heading {
  color: rgb(232, 230, 227);
  font-size: 64px;
  font-weight: bold;
}

.small-heading {
  font-size: 48px;
  color: var(--accent-color);
  margin-top: 60px;
}

.smaller-heading {
  font-size: 36px;
  color: rgb(169, 162, 151);
}

@media only screen and (max-width: 470px) {
  /* Add your styles for small mobile devices here */

  .heading {
    color: rgb(232, 230, 227);
    font-size: 40px;
    font-weight: bold;
  }

  .small-heading {
    font-size: 24px;
    color: var(--accent-color);
    margin-top: 36px;
  }

  .smaller-heading {
    font-size: 18px;
    color: rgb(169, 162, 151);
  }
}

@media only screen and (min-width: 471px) and (max-width: 767px) {
  /* Add your styles for small mobile devices here */

  .heading {
    color: rgb(232, 230, 227);
    font-size: 52px;
    font-weight: bold;
  }

  .small-heading {
    font-size: 36px;
    color: var(--accent-color);
    margin-top: 48px;
  }

  .smaller-heading {
    font-size: 24px;
    color: rgb(169, 162, 151);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* Add your styles for small mobile devices here */

  .heading {
    color: rgb(232, 230, 227);
    font-size: 64px;
    font-weight: bold;
  }

  .small-heading {
    font-size: 48px;
    color: var(--accent-color);
    margin-top: 60px;
  }

  .smaller-heading {
    font-size: 36px;
    color: rgb(169, 162, 151);
  }
}

@media only screen and (min-width: 992px) {
  /* Add your styles for large desktop devices here */

  .heading {
    color: rgb(232, 230, 227);
    font-size: 64px;
    font-weight: bold;
  }

  .small-heading {
    font-size: 48px;
    color: var(--accent-color);
    margin-top: 60px;
  }

  .smaller-heading {
    font-size: 36px;
    color: rgb(169, 162, 151);
  }
}
