@media only screen and (max-width: 580px) {
  .navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(128, 128, 128, 0.148);
    backdrop-filter: 10px;
    padding: 10px;
    transition: transform 0.3s;
    z-index: 1000;
  }

  .navbar-hidden {
    transform: translateY(-100%);
  }

  .navbar-visible {
    transform: translateY(0);
  }

  .logo {
    max-width: 5%;
    max-height: 5%;
  }

  .header {
    padding-bottom: 50px;
  }

  .header-nums {
    color: var(--accent-color);
    font-family: monospace;
    font-size: large;
    height: 0px !important;
  }

  a {
    height: 0px;
    color: red;
  }

  .header-text {
    font-family: monospace;
    font-size: 0% !important;
    color: rgb(177, 170, 160);
    transition: 0.3s;
  }

  .header-text:hover {
    color: var(--accent-color);
  }

  #scroll-button {
    color: rgb(177, 170, 160);
    transition: 0.3s;
  }

  #scroll-button:hover {
    color: var(--accent-color);
  }
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(128, 128, 128, 0.148);
  backdrop-filter: 10px;
  padding: 10px;
  transition: transform 0.3s;
  z-index: 1000;
}

.navbar-hidden {
  transform: translateY(-100%);
}

.navbar-visible {
  transform: translateY(0);
}

.logo {
  max-width: 5%;
  max-height: 5%;
}

.header {
  padding-bottom: 50px;
}

.header-nums {
  color: var(--accent-color);
  font-family: monospace;
  font-size: large;
}

.header-text {
  font-family: monospace;
  font-size: 90%;
  color: rgb(177, 170, 160);
  transition: 0.3s;
}

.header-text:hover {
  color: var(--accent-color);
}

#scroll-button {
  color: rgb(177, 170, 160);
  transition: 0.3s;
}

#scroll-button:hover {
  color: var(--accent-color);
}
