@media only screen and (max-width: 470px) {
  #work-heading {
    font-size: large;
  }
  #num-heading {
    font-size: larger;
  }

  .project1 {
    width: 100%;
    outline: 0px solid #121219;
    border-radius: 5px;

    display: grid;

    margin: auto;
    grid-template-rows: 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr;
    grid-template-columns: 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr;
  }

  #p1 {
    grid-column-start: 2;
    grid-column-end: 8;
    grid-row-start: 2;
    height: fit-content;
    margin-bottom: 10px;
  }

  #p0-img {
    padding: 0px;
    margin-left: 15%;
    grid-row-start: 8;
    grid-column-start: 1;
    grid-column-end: 8;
    height: 0px;
    width: 0px;
  }

  #p1-img {
    padding: 0px;
    margin-left: 15%;
    grid-row-start: 8;
    grid-column-start: 1;
    grid-column-end: 8;
    height: 0px;
    width: 0px;
  }

  #p1-tech {
    margin-top: 10px;
    text-align: center;
    margin: auto;
    grid-row-start: 7;
    grid-column-start: 2;
    grid-column-end: 8;
    position: relative;
    margin: auto;
  }

  .project2 {
    width: 100%;
    outline: 0px solid #121219;
    border-radius: 5px;

    display: grid;

    margin: auto;
    grid-template-rows: 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr;
    grid-template-columns: 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr;
  }

  #p2 {
    grid-column-start: 2;
    grid-column-end: 8;
    grid-row-start: 2;
    grid-row-end: 8;
    margin-bottom: px;
    height: fit-content;
  }

  #p2-img {
    padding: 0px;
    margin-left: 15%;
    grid-row-start: 8;
    grid-column-start: 1;
    grid-column-end: 8;
    height: 0px;
    width: 0px;
  }

  #p2-tech {
    text-align: center;
    margin: auto;
    grid-row-start: 8;
    grid-column-start: 2;
    grid-column-end: 8;
    position: relative;
    margin: auto;
    padding-top: 15px;
    margin-bottom: 10px;
  }

  .project3 {
    width: 100%;
    outline: 0px solid #121219;
    border-radius: 5px;
    margin-top: 20px;
    display: grid;

    margin: auto;
    grid-template-rows: 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr;
    grid-template-columns: 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr;
  }

  #p3 {
    grid-column-start: 2;
    grid-column-end: 8;
    grid-row-start: 2;
    grid-row-end: 8;
    margin-bottom: px;
    height: fit-content;
    margin-top: 0px;
  }

  #p3-img {
    padding: 0px;
    margin-left: 15%;
    grid-row-start: 8;
    grid-column-start: 1;
    grid-column-end: 8;
    height: 0px;
    width: 0px;
  }

  #p3-tech {
    text-align: center;
    grid-row-start: 8;
    grid-row-end: 10;

    grid-column-start: 2;
    grid-column-end: 8;
    padding-top: 15px;
    transform: translateX(-2px);
  }

  #pt3 {
    font-size: large;
    grid-column-start: 2;
    grid-column-end: 8;
    text-wrap: nowrap;
  }

  .additional-projects-container {
    display: grid;
    grid-template-rows: 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr;
    grid-template-columns: 0.35fr 0.5fr 0.5fr 0.5fr 0.5fr 0.35fr;
    row-gap: 25px;
  }

  .p1 {
    transition: 0.3s;
    grid-column-start: 2;
    grid-column-end: 6;
    margin: 0px;
  }

  .p1:hover {
    transform: translate(0, -10px);
    filter: brightness(120%);
  }

  .p1-title {
    grid-column-start: 3;
  }

  .p2 {
    transition: 0.3s;
    grid-row-start: 2;
    grid-column-start: 2;
    grid-column-end: 6;
    margin: 0px;
  }

  .p2:hover {
    transform: translate(0, -10px);
    filter: brightness(120%);
  }

  .p3 {
    grid-row-start: 3;
    grid-column-start: 2;
    grid-column-end: 6;
    transition: 0.3s;
  }

  .p3:hover {
    transform: translate(0, -10px);
    filter: brightness(120%);
  }

  .p4:hover {
    transform: translate(0, -10px);
    filter: brightness(120%);
  }

  .p4 {
    grid-row-start: 4;
    grid-column-start: 2;
    grid-column-end: 6;
    transition: 0.3s;
  }

  .p5 {
    grid-row-start: 5;
    grid-column-start: 2;
    grid-column-end: 6;
    transition: 0.3s;
  }

  .p5:hover {
    transform: translate(0, -10px);
    filter: brightness(120%);
  }

  .p6 {
    grid-row-start: 6;
    grid-column-start: 2;
    grid-column-end: 6;
    transition: 0.3s;
  }

  .p6:hover {
    transform: translate(0, -10px);
    filter: brightness(120%);
  }
}

@media only screen and (min-width: 471px) and (max-width: 767px) {
  #work-heading {
    font-size: large;
  }
  #num-heading {
    font-size: larger;
  }

  .additional-projects-container {
    display: grid;
    grid-template-rows: 0.5fr 0.5fr 0.5fr;
    grid-template-columns: 0.37fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.37fr;
  }

  .p1 {
    transition: 0.3s;
    grid-column-start: 2;
    grid-column-end: 5;
    margin: 10px;
  }

  .p1:hover {
    transform: translate(0, -10px);
    filter: brightness(120%);
  }

  .p1-title {
    grid-column-start: 3;
  }

  .p2 {
    transition: 0.3s;
    grid-column-start: 5;
    grid-column-end: 8;
    margin: 10px;
  }

  .p2:hover {
    transform: translate(0, -10px);
    filter: brightness(120%);
  }

  .p3 {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 2;
    grid-column-end: 5;
    margin: 10px;
    transition: 0.3s;
  }

  .p3:hover {
    transform: translate(0, -10px);
    filter: brightness(120%);
  }

  .p4:hover {
    transform: translate(0, -10px);
    filter: brightness(120%);
  }

  .p4 {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 5;
    grid-column-end: 8;
    margin: 10px;
    transition: 0.3s;
  }

  .p5 {
    grid-row-start: 3;
    grid-row-end: 4;
    grid-column-start: 2;
    grid-column-end: 5;
    margin: 10px;
    transition: 0.3s;
  }

  .p5:hover {
    transform: translate(0, -10px);
    filter: brightness(120%);
  }

  .p6 {
    grid-row-start: 3;
    grid-row-end: 4;
    grid-column-start: 5;
    grid-column-end: 8;
    margin: 10px;
    transition: 0.3s;
  }

  .p6:hover {
    transform: translate(0, -10px);
    filter: brightness(120%);
  }

  #p1 {
    grid-row-start: 2;
    grid-column-start: 2;
    grid-column-end: 8;
    height: fit-content;
  }

  .project1 {
    width: 100%;
    outline: 0px solid #121219;
    border-radius: 5px;

    display: grid;

    margin: auto;
    grid-template-rows: 0.2fr 0.2fr 0.2fr 0.2fr 0.1fr 0.2fr 0.2fr 0.2fr 0.2fr 1.2fr 0.2fr;
    grid-template-columns: 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr;
  }

  #p0-img {
    grid-row-start: 10;
    grid-row-end: 12;
    grid-column-start: 3;
    grid-column-end: 7;
    max-width: 475px;
    min-height: 190px;
  }

  #p1-img {
    grid-row-start: 10;
    grid-row-end: 12;
    grid-column-start: 3;
    grid-column-end: 7;
    max-width: 475px;
    min-height: 190px;
  }

  #p1-tech {
    margin: auto;
    grid-row-start: 8;
    grid-row-end: 8;

    grid-column-end: 8;
    position: relative;
    margin: auto;
  }

  #p2 {
    grid-row-start: 2;
    grid-column-start: 2;
    grid-column-end: 8;
    height: fit-content;
  }

  .project2 {
    width: 100%;
    outline: 0px solid #121219;
    border-radius: 5px;

    display: grid;

    margin: auto;
    grid-template-rows: 0.2fr 0.2fr 0.2fr 0.2fr 0.1fr 0.2fr 0.2fr 0.2fr 0.2fr 1.2fr 0.2fr;
    grid-template-columns: 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr;
  }

  #p2-img {
    grid-row-start: 10;
    grid-row-end: 12;
    box-shadow: inset 0 0 0 150px #0a363600;
    grid-column-start: 3;
    grid-column-end: 7;
    max-width: 475px;
    min-height: 190px;
  }

  #p2-tech {
    margin: auto;
    grid-row-start: 8;
    grid-row-end: 8;
    grid-column-start: 2;
    grid-column-end: 8;
  }

  #p3 {
    grid-row-start: 2;
    grid-column-start: 2;
    grid-column-end: 8;
    height: fit-content;
  }

  .project3 {
    width: 100%;
    outline: 0px solid #121219;
    border-radius: 5px;

    display: grid;

    margin: auto;
    grid-template-rows: 0.2fr 0.2fr 0.2fr 0.2fr 0.1fr 0.2fr 0.2fr 0.2fr 0.2fr 1.2fr 0.2fr;
    grid-template-columns: 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr;
  }

  #p3-img {
    display: flex;
    grid-row-start: 10;
    grid-row-end: 11;
    grid-column-start: 3;
    grid-column-end: 7;
    max-width: 475px;
    min-height: 190px;
  }

  #p3-tech {
    margin: auto;
    grid-row-start: 8;
    grid-row-end: 8;
    grid-column-start: 2;
    grid-column-end: 8;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .link-to-repo {
    grid-column-start: 8 !important;
  }

  #p1 {
    grid-row-start: 3;
    grid-column-start: 2;
    grid-column-end: 7;
    height: fit-content;
  }

  #p0-img:hover {
    box-shadow: inset 0 0 0 150px #0000;
    background-repeat: no-repeat;
  }

  #p1-img:hover {
    box-shadow: inset 0 0 0 150px #0000;
    background-repeat: no-repeat;
  }

  .project1 {
    width: 100%;
    outline: 0px solid #121219;
    border-radius: 5px;

    display: grid;

    margin: auto;
    grid-template-rows: 0.2fr 0.2fr 0.2fr 0.2fr 0.1fr 0.2fr 0.2fr 0.1fr 0.2fr 0.2fr;
    grid-template-columns: 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr;
  }

  #p0-img {
    grid-row-start: 2;
    grid-row-end: 11;
    box-shadow: inset 0 0 0 180px #160a36a9;
    grid-column-start: 4;
    grid-column-end: 9;
    max-width: 475px;
    min-height: 190px;
  }

  #p1-img {
    grid-row-start: 2;
    grid-row-end: 11;
    box-shadow: inset 0 0 0 150px #160a36a9;
    grid-column-start: 4;
    grid-column-end: 9;
    max-width: 475px;
    min-height: 190px;
  }

  #p1-tech {
    grid-row-start: 9;
    grid-row-end: 9;
    grid-column-start: 2;

    grid-column-end: 7;
    margin-bottom: 10px;
  }

  #p2 {
    grid-row-start: 3;
    grid-column-start: 2;
    grid-column-end: 7;
    height: fit-content;
  }

  #p2-img:hover {
    box-shadow: inset 0 0 0 150px #0000;
    background-repeat: no-repeat;
  }

  .project2 {
    width: 100%;
    outline: 0px solid #121219;
    border-radius: 5px;

    display: grid;

    margin: auto;
    grid-template-rows: 0.2fr 0.2fr 0.2fr 0.2fr 0.1fr 0.2fr 0.2fr 0.1fr 0.2fr 0.2fr;
    grid-template-columns: 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr;
  }

  #p2-img {
    grid-row-start: 2;
    grid-row-end: 11;
    box-shadow: inset 0 0 0 150px #160a36a9;
    grid-column-start: 4;
    grid-column-end: 9;
    max-width: 475px;
    min-height: 190px;
  }

  #p2-tech {
    text-align: left;
    grid-row-start: 9;
    grid-row-end: 9;
    grid-column-start: 2;

    grid-column-end: 7;
    margin-bottom: 10px;
  }

  #p3 {
    grid-row-start: 3;
    grid-column-start: 2;
    grid-column-end: 7;
    height: fit-content;
  }

  #p3-img:hover {
    box-shadow: inset 0 0 0 150px #0000;
    background-repeat: no-repeat;
  }

  .project3 {
    width: 100%;
    outline: 0px solid #121219;
    border-radius: 5px;

    display: grid;

    margin: auto;
    grid-template-rows: 0.2fr 0.2fr 0.2fr 0.2fr 0.1fr 0.2fr 0.2fr 0.1fr 0.2fr 0.2fr;
    grid-template-columns: 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr;
  }

  #p3-img {
    grid-row-start: 2;
    grid-row-end: 11;
    box-shadow: inset 0 0 0 150px #1e441e6e;
    grid-column-start: 4;
    grid-column-end: 9;
    max-width: 475px;
    min-height: 190px;
  }

  #p3-tech {
    text-align: left;

    grid-row-start: 9;
    grid-row-end: 9;
    grid-column-start: 2;

    grid-column-end: 7;
    margin-bottom: 10px;
  }

  .additional-projects-container {
    display: grid;
    grid-template-rows: 0.5fr 0.5fr 0.5fr;
    grid-template-columns: 0.37fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.37fr;
  }

  .p1 {
    transition: 0.3s;
    grid-column-start: 2;
    grid-column-end: 5;
    margin: 10px;
  }

  .p1:hover {
    transform: translate(0, -10px);
    filter: brightness(120%);
  }

  .p1-title {
    grid-column-start: 3;
  }

  .p2 {
    transition: 0.3s;
    grid-column-start: 5;
    grid-column-end: 8;
    margin: 10px;
  }

  .p2:hover {
    transform: translate(0, -10px);
    filter: brightness(120%);
  }

  .p3 {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 2;
    grid-column-end: 5;
    margin: 10px;
    transition: 0.3s;
  }

  .p3:hover {
    transform: translate(0, -10px);
    filter: brightness(120%);
  }

  .p4:hover {
    transform: translate(0, -10px);
    filter: brightness(120%);
  }

  .p4 {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 5;
    grid-column-end: 8;
    margin: 10px;
    transition: 0.3s;
  }

  .p5 {
    grid-row-start: 3;
    grid-row-end: 4;
    grid-column-start: 2;
    grid-column-end: 5;
    margin: 10px;
    transition: 0.3s;
  }

  .p5:hover {
    transform: translate(0, -10px);
    filter: brightness(120%);
  }

  .p6 {
    grid-row-start: 3;
    grid-row-end: 4;
    grid-column-start: 5;
    grid-column-end: 8;
    margin: 10px;
    transition: 0.3s;
  }

  .p6:hover {
    transform: translate(0, -10px);
    filter: brightness(120%);
  }
}

/* Extra large devices (large desktops) */
@media screen and (min-width: 992px) {
  /* Your styles for extra large screens go here */
  .link-to-repo {
    grid-column-start: 8 !important;
  }

  #p1 {
    grid-row-start: 3;
    grid-column-start: 2;
    grid-column-end: 7;
    height: fit-content;
  }

  #p0-img:hover {
    box-shadow: inset 0 0 0 150px #0000;
    background-repeat: no-repeat;
  }

  #p1-img:hover {
    box-shadow: inset 0 0 0 150px #0000;
    background-repeat: no-repeat;
  }

  .project1 {
    width: 100%;
    outline: 0px solid #121219;
    border-radius: 5px;

    display: grid;

    margin: auto;
    grid-template-rows: 0.2fr 0.2fr 0.2fr 0.2fr 0.1fr 0.2fr 0.2fr 0.1fr 0.2fr 0.2fr;
    grid-template-columns: 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr;
  }

  #p0-img {
    grid-row-start: 2;
    grid-row-end: 11;
    box-shadow: inset 0 0 0 150px #160a36a9;
    grid-column-start: 5;
    grid-column-end: 9;
    max-width: 475px;
    min-height: 190px;
  }

  #p1-img {
    grid-row-start: 2;
    grid-row-end: 11;
    box-shadow: inset 0 0 0 150px #160a36a9;
    grid-column-start: 5;
    grid-column-end: 9;
    max-width: 475px;
    min-height: 190px;
  }

  #p1-tech {
    grid-row-start: 9;
    grid-row-end: 9;
    grid-column-start: 2;

    grid-column-end: 7;
    margin-bottom: 10px;
  }

  #p2 {
    grid-row-start: 3;
    grid-column-start: 2;
    grid-column-end: 7;
    height: fit-content;
  }

  #p2-img:hover {
    box-shadow: inset 0 0 0 150px #0000;
    background-repeat: no-repeat;
  }

  .project2 {
    width: 100%;
    outline: 0px solid #121219;
    border-radius: 5px;

    display: grid;

    margin: auto;
    grid-template-rows: 0.2fr 0.2fr 0.2fr 0.2fr 0.1fr 0.2fr 0.2fr 0.1fr 0.2fr 0.2fr;
    grid-template-columns: 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr;
  }

  #p2-img {
    grid-row-start: 2;
    grid-row-end: 11;
    box-shadow: inset 0 0 0 150px #160a36a9;
    grid-column-start: 5;
    grid-column-end: 9;
    max-width: 475px;
    min-height: 190px;
  }

  #p2-tech {
    text-align: left;
    grid-row-start: 9;
    grid-row-end: 9;
    grid-column-start: 2;

    grid-column-end: 7;
    margin-bottom: 10px;
  }

  #p3 {
    grid-row-start: 3;
    grid-column-start: 2;
    grid-column-end: 7;
    height: fit-content;
  }

  #p3-img:hover {
    box-shadow: inset 0 0 0 150px #0000;
    background-repeat: no-repeat;
  }

  .project3 {
    width: 100%;
    outline: 0px solid #121219;
    border-radius: 5px;

    display: grid;

    margin: auto;
    grid-template-rows: 0.2fr 0.2fr 0.2fr 0.2fr 0.1fr 0.2fr 0.2fr 0.1fr 0.2fr 0.2fr;
    grid-template-columns: 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr;
  }

  #p3-img {
    grid-row-start: 2;
    grid-row-end: 11;
    box-shadow: inset 0 0 0 150px #160a36a9;
    grid-column-start: 5;
    grid-column-end: 9;
    max-width: 475px;
    min-height: 190px;
  }

  #p3-tech {
    text-align: left;

    grid-row-start: 9;
    grid-row-end: 9;
    grid-column-start: 2;

    grid-column-end: 7;
    margin-bottom: 10px;
  }

  .additional-projects-container {
    display: grid;
    grid-template-rows: 0.5fr 0.5fr 0.5fr;
    grid-template-columns: 0.37fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.37fr;
  }

  .p1 {
    transition: 0.3s;
    grid-column-start: 2;
    grid-column-end: 5;
    margin: 10px;
  }

  .p1:hover {
    transform: translate(0, -10px);
    filter: brightness(120%);
  }

  .p1-title {
    grid-column-start: 3;
  }

  .p2 {
    transition: 0.3s;
    grid-column-start: 5;
    grid-column-end: 8;
    margin: 10px;
  }

  .p2:hover {
    transform: translate(0, -10px);
    filter: brightness(120%);
  }

  .p3 {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 2;
    grid-column-end: 5;
    margin: 10px;
    transition: 0.3s;
  }

  .p3:hover {
    transform: translate(0, -10px);
    filter: brightness(120%);
  }

  .p4:hover {
    transform: translate(0, -10px);
    filter: brightness(120%);
  }

  .p4 {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 5;
    grid-column-end: 8;
    margin: 10px;
    transition: 0.3s;
  }

  .p5 {
    grid-row-start: 3;
    grid-row-end: 4;
    grid-column-start: 2;
    grid-column-end: 5;
    margin: 10px;
    transition: 0.3s;
  }

  .p5:hover {
    transform: translate(0, -10px);
    filter: brightness(120%);
  }

  .p6 {
    grid-row-start: 3;
    grid-row-end: 4;
    grid-column-start: 5;
    grid-column-end: 8;
    margin: 10px;
    transition: 0.3s;
  }

  .p6:hover {
    transform: translate(0, -10px);
    filter: brightness(120%);
  }
}

@media screen and (min-width: 1330px) {
  .link-to-repo {
    grid-column-start: 8 !important;
    text-align: left !important;
    color: rgb(191, 216, 208);
    font-size: 58px;
    transform: translateY(-5px);
    align-self: start;
    float: right;
  }

  .project1 {
    width: 100%;
    outline: 0px solid #121219;
    border-radius: 5px;
    padding-left: 10%;
    padding-right: 10%;

    display: grid;

    grid-template-rows: 0.2fr 0.2fr 0.2fr 0.2fr 0.1fr 0.2fr 0.2fr 0.1fr 0.2fr 0.2fr;
    grid-template-columns: 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr;
  }
}

.hover-item {
  transition: 0.3s;
  display: grid;
  grid-template-columns: 1fr 0.5fr 0.2fr;
  grid-template-rows: 1fr 1.5fr 1fr;
  background-color: black;
  box-shadow: black 0 10px 30px -15px;
}

.hover-item:hover {
  transform: translate(0, -10px);
  filter: brightness(120%);
}

.project {
  color: var(--accent-color);
  font-family: monospace;
}

.project-title {
  transition: 0.3s;
  color: #d1e3dd;
  font-weight: bold;
}

.title {
  color: #d1e3dd;
}

#project-skills {
  word-spacing: 0px;
  color: #d1e3dd;
}

.tech {
  font-family: monospace;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

#card {
  background-color: var(--card-background);
  border: 0px;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.github {
  color: gray;
  text-decoration: none;
  transition: 0.3s;
  font-size: 1.75em;
}

.links {
  color: #bfd8d0;
  text-decoration: none;
  transition: 0.3s;
  font-size: 32px;
}

.links:hover {
  color: var(--accent-color);
  text-decoration: none;
  transition: 0.3s;
  transform: translate(0, -10px);
}

.github:hover {
  filter: brightness(120%);
}

.horizontal-line {
  border-top: 1px solid gray; /* Adjust the color as needed */
  flex: 1;
  height: 0; /* Set height to 0 to ensure the line doesn't take up additional space */
  margin-top: 0.5em; /* Adjust the margin as needed to properly position the line */
  margin-bottom: 0.5em; /* Adjust the margin as needed to properly position the line */
  width: 10;
  margin-right: 3em;
  margin-left: 1.75em;
  margin-top: 1.5em;
}

/*.project1 {
  width: 100%;
  height: 28em;
  outline: 0px solid #121219;
  border-radius: 5px;
  display: grid;

  margin: auto;
  grid-template-rows: 1fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 1fr;
  grid-template-columns: 0.43fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.43fr;
}
*/

.project1-title {
  grid-column-start: 2;
}

.project0-description {
  color: rgb(191, 216, 208);
  padding: 10px;
  grid-row: 3 / 7;
  grid-column: 2 / 6;
  font-size: 16px;
  border: 0px gray;
  transition: 0.3s;
  background-color: var(--card-background);
  z-index: 1;
  border-radius: 5px;
  box-shadow: 0 20px 30px -15px black;
}

.project0-image {
  grid-row: 2 / 8;
  grid-column: 3 / 8;
  max-width: 480px;
  background: url(../assets/zglam-demo.png);
  border-radius: 5px;
  background-size: cover;
  background-position: 0px 0px;
  transition: 0.3s;
  grid-row-start: 2;
  grid-row-end: 8;
  grid-column-start: 3;
}

.project1-description {
  color: rgb(191, 216, 208);
  padding: 10px;
  grid-row: 3 / 7;
  grid-column: 2 / 6;
  font-size: 16px;
  border: 0px gray;
  transition: 0.3s;
  background-color: var(--card-background);
  z-index: 1;
  border-radius: 5px;
  box-shadow: 0 20px 30px -15px black;
}

.project1-image {
  grid-row: 2 / 8;
  grid-column: 3 / 8;
  max-width: 480px;
  background: url(../assets/spotlyrics_demo.png);
  border-radius: 5px;
  background-size: cover;
  background-position: -10px 0px;
  transition: 0.3s;
  grid-row-start: 2;
  grid-row-end: 8;
  grid-column-start: 3;
}

.project1-image:hover {
  box-shadow: inset 0 0 0 150px #0000;
  background-repeat: no-repeat;
}

.project1-tech {
  grid-row-start: 7;
  grid-column-start: 2;
  grid-row-end: 8;
  grid-column-end: 6;
  z-index: 1;
  min-width: 0;
  min-height: 0;
  padding: 10px;
  color: #d1e3dd;
}

/*Project 2 CSS*/

.project2 {
  width: 100%;
  height: 28em;
  outline: 0px solid #121219;
  border-radius: 5px;
  display: grid;

  margin: auto;
  grid-template-rows: 1fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 1fr;
  grid-template-columns: 0.43fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.43fr;
}

.project2-title {
  grid-column-start: 2;
  color: aqua;
}

.project2-description {
  padding: 10px;
  grid-row: 3 / 7;
  grid-column: 4 / 8;
  font-size: 16px;
  border: 0px gray;
  transition: 0.3s;
  background-color: var(--card-background);
  color: rgb(191, 216, 208);
  z-index: 1;
  border-radius: 5px;
  box-shadow: 0 20px 30px -15px black;
}

.project2-image {
  grid-row: 2 / 8;
  grid-column: 2 / 6;
  background: url(../assets/amps_demo.png);
  border-radius: 5px;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: inset 0 0 0 150px #140a36a9;
  transition: 0.3s;
}

.project2-image:hover {
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: inset 0 0 0 150px #0000;
}

.project2-tech {
  grid-row-start: 7;
  grid-column-start: 4;
  grid-row-end: 8;
  grid-column-end: 8;
  z-index: 1;
  min-width: 0;
  min-height: 0;
  padding: 10px;
  color: #d1e3dd;
  text-align: right;
}

/*Project 3 CSS*/

.project3-title {
  grid-column-start: 2;
  grid-column-end: 5;
  z-index: 1;
}

.project3-image {
  grid-row: 2 / 8;
  grid-column: 3 / 8;
  /*max-width: 480px;*/
  background: url(../assets/project-productive_demo.png);
  border-radius: 5px;
  background-size: cover;
  transition: 0.3s;
  grid-row-start: 2;
  grid-row-end: 8;
  grid-column-start: 2;
}

.project3-image:hover {
  box-shadow: inset 0 0 0 150px #0000;
}

/*Additional Projects CSS*/

.more-projects {
  padding-top: 30px;
  margin: auto;
  text-align: center;
  padding-bottom: 8vh;
}

.description {
  color: rgb(177, 170, 160);
}

.link-to-repo {
  grid-column-start: 7;
  text-align: right;
  color: rgb(191, 216, 208);
  font-size: 58px;
  transform: translateY(-5px);
  align-self: start;
  float: right;
}
