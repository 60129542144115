@media only screen and (max-width: 518px) {
  /* Add your styles for small mobile devices here */
  #work-heading {
    font-size: large;
  }
  #num-heading {
    font-size: larger;
  }

  .date-attended {
    font-size: 80%;
  }

  .button-container {
    margin-left: 10%;
    margin-right: 5%;
    gap: 10px;
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 15px;
    font-size: 70%;
  }

  #company-selector {
    margin-left: 10px;
    margin-right: 10px;
  }

  #company-selector > button {
    box-shadow: 0px 0.5px 0 var(--accent-color);

    white-space: nowrap;
    text-align: left;
  }

  .education {
    margin-left: 4.5em;
    padding-left: 10px;
    margin-right: 5em;

    /*box-shadow: -3px 0px 0 var(--accent-color);*/
    margin-top: 20px;
    font-size: large;
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 10px;
    margin-bottom: 20px;
    font-size: 80%;
  }

  .role {
    font-size: large;
  }
  .company-name {
    font-size: large;
  }

  .tenure {
    font-family: monospace;
    font-size: 80%;
    color: #d1e3dd;
  }

  li {
    font-size: 95%;
    color: var(--near-white-text);
  }

  #education-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    font-size: large;
  }

  #education-header {
    grid-column-start: 2;
    text-align: center;
    font-size: large;
    padding-bottom: 15px;
  }

  #description-container {
    margin: auto;
    display: grid;
    gap: 20px;
    padding: 20px;
    padding-left: 3.3em;
    padding-right: 3.3em;
  }

  #inactive-button {
    color: #d1e3dd;
    text-align: center;
    font-weight: bold;
    padding: none;
  }

  #inactive-button:hover {
    background: none;
  }

  #active-button {
    color: var(--accent-color);
    text-align: center;
    font-weight: bold;
    padding: none;
  }

  #active-button:hover {
    background: none;
  }

  #company-selector {
    margin: none;
  }

  #csun {
    font-size: 80%;
  }

  #date {
    font-size: medium;
    padding-bottom: 3px;
  }

  .date-attended {
    grid-column-start: 2;
    margin: auto;
    vertical-align: middle;
    transform: translate(0%, 25%);
    font-size: 90%;
  }

  .role {
    color: rgb(216, 212, 207);
    font-family: "Heebo", sans-serif;
  }

  .company-name {
    color: var(--accent-color);
    font-family: "Heebo", sans-serif;
  }
}

@media only screen and (min-width: 519px) and (max-width: 767px) {
  /* Add your styles for small mobile devices here */
  #work-heading {
    font-size: large;
  }
  #num-heading {
    font-size: larger;
  }

  #date {
    font-size: medium;
    padding-bottom: 3px;
  }

  .role {
    color: rgb(216, 212, 207);
    font-family: "Heebo", sans-serif;
  }

  .company-name {
    color: var(--accent-color);
    font-family: "Heebo", sans-serif;
  }

  .button-container {
    display: flex;
    text-align: center;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 15px;
    font-size: 76%;
  }

  #company-selector {
    margin-left: 10px;
    margin-right: 10px;
  }

  #company-selector > button {
    white-space: nowrap;
    text-align: center;
  }

  .education {
    margin-left: 4.5em;
    padding-left: 10px;
    margin-right: 5em;

    box-shadow: -3px 0px 0 var(--accent-color);
    margin-top: 20px;
    font-size: large;
    display: grid;
    grid-template-columns: 70% 10%;
    gap: 10px;
    margin-bottom: 20px;
    font-size: 100%;
  }

  .role {
    font-size: larger;
  }
  .company-name {
    font-size: larger;
  }

  .tenure {
    font-family: monospace;
    font-size: 100%;
    color: #d1e3dd;
  }

  li {
    font-size: 95%;
    color: var(--near-white-text);
  }

  #education-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    font-size: large;
  }

  #education-header {
    grid-column-start: 2;
    text-align: center;
    font-size: large;
    padding-bottom: 15px;
  }

  #description-container {
    margin: auto;
    display: grid;
    gap: 20px;
    padding: 20px;
    padding-left: 5.3em;
    padding-right: 5.3em;
    font-size: large;
  }

  #inactive-button {
    color: #d1e3dd;
    text-align: center;
    font-weight: bold;
    padding: none;
  }

  #inactive-button:hover {
    background: none;
  }

  #active-button {
    color: var(--accent-color);
    text-align: center;
    font-weight: bold;
    padding: none;
  }

  #active-button:hover {
    background: none;
  }

  #company-selector {
    margin: none;
  }

  #csun {
    font-size: 90%;
  }

  .date-attended {
    grid-column-start: 2;
    margin: auto;
    vertical-align: middle;
    transform: translate(0%, 25%);
    font-size: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* Add your styles for small mobile devices here */
  .company-description-container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 20px;
    padding: 20px;
  }

  .button-container {
    text-align: center;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.1fr 0.1fr 0.1fr 0.1fr;
    gap: 0;
    float: left;
    padding-top: 20px;
  }

  .align {
    margin-left: 6rem;
    margin-right: 6rem;
  }

  #education-header {
    padding-left: 1.6em;
    font-size: x-large;
    padding-bottom: 15px;
  }

  button {
    max-width: 135px;
  }

  #active-button {
    background-color: #251e44;
    /* filter: brightness(150%);*/
    color: var(--accent-color);
    margin: 0px;
    padding: 5px 10px;
    border: none;
    border-radius: 1px;
    cursor: pointer;
    box-shadow: -3px 0px 0 var(--accent-color);
  }

  #inactive-button {
    background-color: none;
    color: #d1e3dd;
    margin: 0px;
    padding: 5px 10px;
    border: none;
    border-radius: 1px;
    cursor: pointer;
    box-shadow: -3px 0px 0 gray;
  }

  .description-container {
    padding-left: 10px;
    padding-right: 25px;
    border-radius: 5px;
    border-width: 10px;
  }

  #work-list {
    padding-left: 10px;
  }

  .heading-container {
    display: flex;
    align-items: center;
  }

  h3 {
    padding-right: 20px;
  }

  span {
    font-size: x-large;
  }

  .title {
    font-size: larger;
    padding-right: 20px;
  }

  .horizontal-line {
    border-top: 1px solid gray; /* Adjust the color as needed */
    flex: 1;
    height: 0; /* Set height to 0 to ensure the line doesn't take up additional space */
    margin-top: 0.5em; /* Adjust the margin as needed to properly position the line */
    margin-bottom: 0.5em; /* Adjust the margin as needed to properly position the line */
    width: 10;
    margin-right: 3em;
    margin-left: 1.75em;
    margin-top: 1.5em;
  }

  .number-heading {
    color: var(--accent-color);
    font-family: "Open Sans", sans-serif;
  }

  .title-heading {
    color: rgb(191, 216, 208);
    font-family: "Open Sans", sans-serif;
  }

  .role {
    color: rgb(216, 212, 207);
    font-family: "Heebo", sans-serif;
  }

  .company-name {
    color: var(--accent-color);
    font-family: "Heebo", sans-serif;
  }

  .tenure {
    font-family: monospace;
    font-size: 90%;
    color: #d1e3dd;
  }

  .education {
    margin-left: 10%;
    padding-left: 10px;
    box-shadow: -3px 0px 0 var(--accent-color);
    margin-top: 20px;
    font-size: large;
    display: grid;
    grid-template-columns: 50% 50%;
    margin-bottom: 20px;
  }

  .degrees {
    font-size: 100%;
    font-family: monospace;
    margin-bottom: 2px;
    color: #d1e3dd;
  }

  .degrees-alt {
    font-size: 100%;
    margin-bottom: 2px;
    color: #d1e3dd;
  }

  li {
    list-style-type: "⪧";
    padding-inline-start: 1.5ch;
    padding-top: 5px;
    padding-bottom: 5px;
    color: var(--near-white-text);
  }

  .date-attended {
    grid-column-start: 2;
    margin: auto;
    vertical-align: middle;
    transform: translate(0%, 25%);
    font-size: 100%;
  }

  .dates {
    padding-top: 2px;
    font-size: 110%;
  }

  #education-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    font-size: large;
  }

  #education-header {
    grid-column-start: 2;
    text-align: center;
    font-size: x-large;
  }

  #work-description {
    padding-right: 3em;
  }
}

@media only screen and (min-width: 992px) {
  /* Add your styles for large desktop devices here */

  #work-description {
    padding-right: 5em;
  }

  .company-description-container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 20px;
    padding: 20px;
  }

  .button-container {
    text-align: center;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.1fr 0.1fr 0.1fr 0.1fr;
    gap: 0;
    float: left;
    padding-top: 20px;
  }

  .align {
    margin-left: 6rem;
    margin-right: 6rem;
  }

  #education-header {
    padding-left: 1.6em;
    font-size: x-large;
    padding-bottom: 15px;
  }

  button {
    max-width: 135px;
  }

  #active-button {
    background-color: #34175a;
    /* filter: brightness(150%);*/
    color: var(--accent-color);
    margin: 0px;
    padding: 5px 10px;
    border: none;
    border-radius: 1px;
    cursor: pointer;
    box-shadow: -3px 0px 0 var(--accent-color);
  }

  #inactive-button {
    background-color: none;
    color: #d1e3dd;
    margin: 0px;
    padding: 5px 10px;
    border: none;
    border-radius: 1px;
    cursor: pointer;
    box-shadow: -3px 0px 0 gray;
  }

  .description-container {
    padding-left: 10px;
    padding-right: 25px;
    border-radius: 5px;
    border-width: 10px;
  }

  #work-list {
    padding-left: 10px;
  }

  .heading-container {
    display: flex;
    align-items: center;
  }

  h3 {
    padding-right: 20px;
  }

  span {
    font-size: x-large;
  }

  .title {
    font-size: larger;
    padding-right: 20px;
  }

  .horizontal-line {
    border-top: 1px solid gray; /* Adjust the color as needed */
    flex: 1;
    height: 0; /* Set height to 0 to ensure the line doesn't take up additional space */
    margin-top: 0.5em; /* Adjust the margin as needed to properly position the line */
    margin-bottom: 0.5em; /* Adjust the margin as needed to properly position the line */
    width: 10;
    margin-right: 3em;
    margin-left: 1.75em;
    margin-top: 1.5em;
  }

  .number-heading {
    color: var(--accent-color);
    font-family: "Open Sans", sans-serif;
  }

  .title-heading {
    color: rgb(191, 216, 208);
    font-family: "Open Sans", sans-serif;
  }

  .role {
    color: rgb(216, 212, 207);
    font-family: "Heebo", sans-serif;
  }

  .company-name {
    color: var(--accent-color);
    font-family: "Heebo", sans-serif;
  }

  .tenure {
    font-family: monospace;
    font-size: 90%;
    color: #d1e3dd;
  }

  .education {
    margin-left: 5.7em;
    padding-left: 10px;
    box-shadow: -3px 0px 0 var(--accent-color);
    margin-top: 20px;
    font-size: large;
    display: grid;
    grid-template-columns: 50% 50%;
    margin-bottom: 20px;
  }

  .degrees {
    font-size: 100%;
    font-family: monospace;
    margin-bottom: 2px;
    color: #d1e3dd;
  }

  .degrees-alt {
    font-size: 100%;
    margin-bottom: 2px;
    color: #d1e3dd;
  }

  li {
    list-style-type: "⪧";
    padding-inline-start: 1.5ch;
    padding-top: 5px;
    padding-bottom: 5px;
    color: var(--near-white-text);
  }

  .date-attended {
    grid-column-start: 2;
    margin: auto;
    vertical-align: middle;
    transform: translate(0%, 25%);
    font-size: 100%;
  }

  .dates {
    padding-top: 2px;
    font-size: 110%;
  }

  #education-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    font-size: large;
  }

  #education-header {
    grid-column-start: 2;
    text-align: center;
    font-size: x-large;
  }
}

/*.company-description-container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 20px;
  padding: 20px;
}
*/

/*.button-container {
  text-align: center;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0.1fr 0.1fr 0.1fr 0.1fr;
  gap: 0;
  float: left;
  padding-top: 20px;
}
*/

.align {
  margin-left: 6rem;
  margin-right: 6rem;
}

button {
  max-width: 145px;
}

/*.active-button {
  background-color: rgb(13, 38, 38);
  color: var(--accent-color);
  margin: 0px;
  padding: 5px 10px;
  border: none;
  border-radius: 1px;
  cursor: pointer;
  box-shadow: -3px 0px 0 var(--accent-color);
}
*/
/*
.inactive-button {
  background-color: none;
  color: #d1e3dd;
  margin: 0px;
  padding: 5px 10px;
  border: none;
  border-radius: 1px;
  cursor: pointer;
  box-shadow: -3px 0px 0 gray;
}
*/

.description-container {
  padding-left: 10px;
  padding-right: 25px;
  border-radius: 5px;
  border-width: 10px;
}

#work-list {
  padding-left: 10px;
}

.heading-container {
  display: flex;
  align-items: center;
}

h3 {
  padding-right: 20px;
}

span {
  font-size: x-large;
}

.title {
  font-size: larger;
  padding-right: 20px;
}

.horizontal-line {
  border-top: 1px solid gray; /* Adjust the color as needed */
  flex: 1;
  height: 0; /* Set height to 0 to ensure the line doesn't take up additional space */
  margin-top: 0.5em; /* Adjust the margin as needed to properly position the line */
  margin-bottom: 0.5em; /* Adjust the margin as needed to properly position the line */
  width: 10;
  margin-right: 3em;
  margin-left: 1.75em;
  margin-top: 1.5em;
}

.number-heading {
  color: var(--accent-color);
  font-family: "Open Sans", sans-serif;
}

.title-heading {
  color: rgb(191, 216, 208);
  font-family: "Open Sans", sans-serif;
}

.company-name {
  color: var(--accent-color);
  font-family: "Heebo", sans-serif;
}

/*.education {
  margin-left: 5.7em;
  padding-left: 10px;
  box-shadow: -3px 0px 0 var(--accent-color);
  margin-top: 20px;
  font-size: large;
  display: grid;
  grid-template-columns: 50% 50%;
  margin-bottom: 20px;
}
*/

.degrees {
  font-size: 100%;
  font-family: monospace;
  margin-bottom: 2px;
  color: #d1e3dd;
}

.degrees-alt {
  font-size: 100%;
  margin-bottom: 2px;
  color: #d1e3dd;
}

li {
  list-style-type: "⪧";
  padding-inline-start: 1.5ch;
  padding-top: 5px;
  padding-bottom: 5px;
}

.date-attended {
  grid-column-start: 2;
  margin: auto;
  vertical-align: middle;
  transform: translate(0%, 25%);
  font-size: 100%;
}

.dates {
  padding-top: 2px;
  font-size: 110%;
}
