@media only screen and (max-width: 470px) {
  /* Add your styles for small mobile devices here */
  .skill-box {
    background-color: none;
    padding-top: 10px;
    display: grid;
    grid-template-columns: 0.5fr 0.5fr;
    row-gap: 30px;
    grid-template-rows: 2fr 2fr 2fr 2fr 2fr 2fr;
    column-gap: 40px;
  }

  .technologies {
    font-size: medium;
  }

  .ts-header {
    width: 50%;
    color: aqua;
  }

  #about-section {
    padding-top: 0px;
  }

  #title-heading {
    font-size: large;
  }
  #number-heading {
    font-size: larger;
  }
}

@media only screen and (min-width: 471px) and (max-width: 767px) {
  /* Add your styles for small mobile devices here */
  .skill-box {
    background-color: none;
    padding-top: 10px;
    display: grid;
    grid-template-columns: 0.5fr 0.5fr 0.5fr;
    row-gap: 30px;
    grid-template-rows: 2fr 2fr 2fr 2fr;
  }
  #about-section {
    padding-top: 20px;
  }
  #title-heading {
    font-size: large;
  }
  #number-heading {
    font-size: larger;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* Add your styles for small mobile devices here */
  .skill-box {
    background-color: none;
    padding-top: 10px;
    display: grid;
    grid-template-columns: 0.5fr 0.5fr 0.5fr 0.5fr;
    row-gap: 30px;
    grid-template-rows: 2fr 2fr 2fr;
  }

  #about-section {
    padding-top: 40px;
  }
  #title-heading {
    font-size: larger;
  }
  #number-heading {
    font-size: larger;
  }
  .center {
    color: white;
  }
}

@media only screen and (min-width: 992px) {
  /* Add your styles for large desktop devices here */
  .skill-box {
    background-color: none;
    padding-top: 10px;
    display: grid;
    grid-template-columns: 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr;
    row-gap: 30px;
    grid-template-rows: 2fr 2fr;
  }

  #about-section {
    padding-top: 60px;
  }
  .center {
    margin: auto;
  }
}

.about {
  color: #e8e6e3;
  font-size: 18px;
  margin-left: 3rem;
  margin-right: 3rem;
  padding-top: 15px;
  padding-bottom: 25px;
}

.skills {
  padding-top: 10px;
  margin-left: 6rem;
  margin-right: 6rem;
  padding-bottom: 25px;
}

#skills-heading {
  font-size: large;
  color: rgb(179, 208, 199);
  font-weight: bold;
}

#tech-stack-header {
  padding-left: 1.6em;
  font-size: x-large;
}

.skill {
  border-bottom: 5px;
  border-color: blue;
  width: 64px;
  max-height: 80px;
  margin: auto;
  background-color: rgb(13, 38, 38);
  border-radius: 5px;
  padding: 10px;
  transition: 0.3s;
}

.skill:hover {
  transform: translate(0, -10px);
  background-color: rgb(6, 18, 18);
}

.text {
  font-size: 12px;

  color: white; /* Set text color to white */
  text-align: center;
  filter: brightness(100%) !important; /* Override filter on the text */
}

.container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: rgba(
    13,
    38,
    38,
    0.621
  ); /* Dark overlay color (adjust opacity as needed) */
  opacity: 0; /* Initially transparent */
  transition: opacity 0.3s;
}

.container {
  position: relative;
  display: inline-block;
}

.overlay {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translateY(-50%); /* Center the overlay vertically */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: 0.3s;
}

#python {
  width: 80px;
  height: 80px;
}

#python svg {
  transform: scale(120%);
  transition: 0.3s;
}

#javascript {
  width: 80px;
  height: 80px;
}

#javascript svg {
  transition: 0.3s;
}

#react {
  width: 80px;
  height: 80px;
}

#react svg {
  transform: scale(110%);
  transition: 0.3s;
}

#bash {
  width: 80px;
  height: 80px;
}

#bash svg {
  transform: scale(120%);
  transition: 0.3s;
}

#linux {
  width: 80px;
  height: 80px;
}

#linux svg {
  transform: scale(110%);
  transition: 0.3s;
}

#azure {
  width: 80px;
  height: 80px;
}

#azure svg {
  transform: scale(105%);
  transition: 0.3s;
}

#docker {
  width: 80px;
  height: 80px;
}

#docker svg {
  transform: scale(120%);
  transition: 0.3s;
}

#git {
  width: 80px;
  height: 80px;
}

#git svg {
  transform: scale(105%);
  transition: 0.3s;
}

#postgres {
  width: 80px;
  height: 80px;
}

#postgres svg {
  transform: scale(105%);
  transition: 0.3s;
}

#bootstrap {
  width: 80px;
  height: 80px;
}

#bootstrap svg {
  transform: scale(105%);
  transition: 0.3s;
}

#tensorflow {
  width: 80px;
  height: 80px;
}

#tensorflow svg {
  transform: scale(105%);
  transition: 0.3s;
}

#rust {
  width: 80px;
  height: 80px;
}

#rust svg {
  transform: scale(105%);
  transition: 0.3s;
}

.container:hover::before {
  opacity: 1; /* Show the dark overlay on hover */
  transition: 0.3s;
}

.container:hover svg {
  filter: brightness(30%); /* Darken the SVG */
  transition: 0.3s;
}

.container:hover .overlay {
  opacity: 1; /* Show the overlay on hover */
  transition: 0.3s;
}

.Parent {
  display: flex;
  flex-direction: column;
}

.child1 {
  width: 70%;
}

.child2 {
  width: 30%;
}

.title-experience {
  font-family: "Red Hat Display", sans-serif;
}

.experience-container {
  background-color: darkslategray;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 10% 1fr 1fr 1fr;
}

button {
  height: 42px;
  background-color: rgb(255, 255, 255, 0);
  transition: 0.3s;
  border-width: 0px;
  width: 65%;
  text-align: left;
}

button:hover {
  background-color: #1a1c1d;

  filter: brightness(150%);
}

.neudesic {
  grid-column-start: 1;
}

.berkus {
  grid-column-start: 1;
}

.teladoc {
  grid-column-start: 1;
}

.kumon {
  grid-column-start: 1;
}

.work-description {
  grid-column-start: 2;
  grid-row-start: 1;
}

.technologies {
  justify-content: center;
  margin: auto;
  display: flex;
  flex-direction: row;
}

.tech1 {
  width: 100%;
}

.tech2 {
  width: 100%;
}
.tech1.li {
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.tech2.li {
  text-align: center;
  width: 100%;

  display: flex;
  flex-direction: column;
}
