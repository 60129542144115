.footer {
  padding-bottom: 10px;
  height: 130px;
  margin-top: 0em;
  background-color: var(--footer-bg);
}

.icon2 {
  font-size: 30px;
  text-decoration: none;
  transition: 0.3s;
  color: rgb(177, 171, 161);
}

.icon2:hover {
  transform: translateY(-5px);
  color: var(--accent-color);
}

.footer-text {
  font-family: monospace;
}

.bio {
  text-decoration: none;
  transition: 0.3s;
}

.bio:hover {
  text-decoration: none;
  color: var(--accent-color);
}
