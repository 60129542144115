@media only screen and (max-width: 470px) {
  .contact-heading {
    font-size: 28px;
    font-weight: bold;
    padding-bottom: 5px;
  }
}
@media only screen and (min-width: 471px) and (max-width: 767px) {
  .contact-heading {
    font-size: 48px;
    font-weight: bold;
    padding-bottom: 5px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-heading {
    font-size: 48px;
    font-weight: bold;
    padding-bottom: 5px;
  }
}
@media only screen and (min-width: 992px) {
  .contact-heading {
    font-size: 48px;
    font-weight: bold;
    padding-bottom: 5px;
  }
}

.heading-container {
  align-items: center;
  margin: auto;
}

.contact {
  margin: auto;
  text-align: center;
  margin-top: 20px;
  color: var(--near-white-text);
  padding-left: 3rem;
  padding-right: 3rem;
  font-family: "Red Hat Display", sans-serif;
  font-weight: bold;
  font-size: larger;
}

.email-btn {
  text-align: center;
  border-width: 1px;
  border-color: var(--accent-color);
  border-radius: 5px;
  color: var(--accent-color);
  transition: 0.3s;
  margin-top: 20px;
}

.email-btn:hover {
  transform: translate(0, -5px);
  filter: brightness(120%);
  background-color: var(--accent-color);
  color: black;
}
